<script lang="ts">
    import { onMount } from 'svelte';
    import type { Post, User } from '@/interfaces/types';
    import BookmarksMenu from '@/elements/Bookmarks/BookmarksMenu.svelte';
    import PostsWrapper from '@/elements/Feed/PostsWrapper.svelte';

    interface BookmarkType {
        heading: string;
        icon: string;
    }

    interface ReportStatus {
        id: number;
        name: string;
    }

    export let posts: Post[];
    export let bookmarkTypes: Record<string, BookmarkType>;
    export let activeTab: string;
    export let reportStatuses: ReportStatus[];

    let currentUser: User | null = null;
    let settings = {
        profiles: {
            allow_users_enabling_open_profiles: true,
        },
    };

    let loadingMore = false;
    let paginatorConfig = {
        next_page_url: null,
        prev_page_url: null,
        current_page: 1,
        total: posts.length,
        per_page: 20,
        hasMore: false,
    };

    async function loadMorePosts() {
        if (loadingMore || !paginatorConfig.hasMore) return;

        loadingMore = true;
        try {
            const response = await fetch(paginatorConfig.next_page_url);
            const data = await response.json();
            posts = [...posts, ...data.data];
            paginatorConfig = {
                ...paginatorConfig,
                ...data.paginatorConfig,
            };
        } catch (error) {
            console.error('Error loading more posts:', error);
        } finally {
            loadingMore = false;
        }
    }

    onMount(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    loadMorePosts();
                }
            },
            { rootMargin: '100px' }
        );

        const target = document.querySelector('.load-more-trigger');
        if (target) observer.observe(target);

        return () => observer.disconnect();
    });
</script>

<div class="">
    <div class="flex flex-wrap">
        <div class="mb-3 w-full pr-0 md:w-1/2 lg:w-1/4">
            <div class="bookmarks-menu-wrapper">
                <div class="ml-3 mt-3">
                    <h5 class="font-bold text-gray-900 dark:text-white">Bookmarks</h5>
                </div>
                <hr class="mb-0" />
                <div>
                    <BookmarksMenu {bookmarkTypes} {activeTab} />
                </div>
            </div>
        </div>

        <div class="mb-5 min-h-screen w-full border-l border-r pl-0 pr-0 md:w-1/2 md:pl-3 md:pr-3 lg:mb-0 lg:w-3/4">
            <div class="mt-0 pt-4">
                <PostsWrapper {posts} {reportStatuses} {currentUser} {settings} />
            </div>
            {#if loadingMore}
                Posts loading spinner
            {/if}
            <div class="load-more-trigger h-1"></div>
        </div>
        checkout box
    </div>
</div>
